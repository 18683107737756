import React from "react"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import * as companyStyles from "./company.module.scss"
import * as valuesStyles from "./values.module.scss"

const Values = ({ pageContext, location }) => {
  const pageTitle = "Values"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div className="">
        <div>
          <div
            className={`${valuesStyles.logoGrid}`}
            style={{ paddingBottom: `3rem` }}
          >
            <div className={valuesStyles.rockyMountainLogo}>
              <img
                src={
                  require("../../assets/images/logos/RockyValuesMountains.svg")
                    .default
                }
                alt=""
              />
            </div>
            <h2 className={`${valuesStyles.rockyBrandsValues}`}>
              <strong style={{ fontWeight: `900` }}>Rocky</strong>
              {` `}
              <span style={{ fontWeight: `700`, color: `#9d5918` }}>
                Brands
              </span>
              {` `}
              <span style={{ fontWeight: `400`, color: `#9d5918` }}>
                Values
              </span>
            </h2>
          </div>
        </div>

        <ul className={`${valuesStyles.valuesCardsGrid}`}>
          <li className={valuesStyles.cardIntegrity}>
            <h3>Integrity</h3>
            <ul className={`bulleted-list ${valuesStyles.cardList}`}>
              <li>Be honest</li>
              <li>Act with conviction</li>
              <li>Own your mistake</li>
              <li>Choose what's right over what's easy</li>
            </ul>
          </li>

          <li className={valuesStyles.card}>
            <h3>Responsibility</h3>
            <ul className={`bulleted-list ${valuesStyles.cardList}`}>
              <li>Do what needs done</li>
              <li>Seek input from others</li>
              <li>Support decisions</li>
              <li>Take risks, be committed</li>
              <li>Work hard, not just for yourself but for others</li>
            </ul>
          </li>

          <li className={valuesStyles.cardHumility}>
            <h3>Humility</h3>
            <ul className={`bulleted-list ${valuesStyles.cardList}`}>
              <li>
                Courageously undertake difficult, tedious, or unglamorous tasks
              </li>
              <li>Graciously accept sacrifices</li>
              <li>Give credit where credit is due</li>
              <li>Be honest with yourself and others</li>
            </ul>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default Values
